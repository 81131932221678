<template>
  <div>
    <VueSlickCarousel
      v-bind="settings"
      class="rn-slider-area slider-activation rn-slick-dot dot-light mb--0"
    >
      <div
        class="slide slide-style-2 d-flex align-center justify-center fullscreen"
        data-black-overlay="8"
        v-for="(slider, i) in sliderContent"
        :key="i"
      >
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="inner text-center">
                <h1 class="heading-title theme-gradient">{{ slider.title }}</h1>
                <p class="description">
                  {{ slider.desc }}
                </p>
                <div class="slide-btn">
                  <a v-if="slider.title == 'Builder Tile & Flooring.'"
                    class="rn-button-style--2 btn-primary-color"
                    href="/contact"
                    >Contact Us</a
                  >
                  <a v-if="slider.title != 'Builder Tile & Flooring.'"
                    class="rn-button-style--2 btn-primary-color"
                    href="/service"
                    >Learn More</a
                  >
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- End Single Slide  -->
    </VueSlickCarousel>
  </div>
</template>

<script>
  import VueSlickCarousel from "vue-slick-carousel";

  export default {
    components: { VueSlickCarousel },
    data() {
      return {
        sliderContent: [
          {
            src: require("../../assets/images/bg/bg-image-15.jpg"),
            title: "Builder Tile & Flooring.",
            desc: ` Quality flooring, quality installation and quality service. We proud to be Austin local.`,
          },
          {
            src: require("../../assets/images/bg/bg-image-13.jpg"),
            title: "Products",
            desc: ` We specialize in residential production new construction builders, but are also versed in custom new-construction, commercial, commercial remodel and multi-family-commercial projects.`,
          },
          {
            src: require("../../assets/images/bg/bg-image-12.jpg"),
            title: "Installation.",
            desc: ` Sun Tile Corporation offers quality installation at affordable prices.`,
          },
        ],
        settings: {
          fade: true,
          dots: true,
          arrows: true,
          infinite: true,
          speed: 1000,
          slidesToShow: 1,
          slidesToScroll: 1,
          margin: 20,
        },
      };
    },
  };
</script>

<style lang="scss">
  .slick-slide {
    img {
      display: block;
      width: 100%;
    }
  }
</style>
